<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <div class="form-row justify-content-between">
          <b-col
            cols="4"
            md="2"
            class="d-none d-sm-block align-items-center justify-content-left"
          >
            <v-select
              label="title"
              required
              v-model="perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </b-col>
          <b-col cols="12" md="4" offset="md-4">
            <b-form-input
              type="search"
              class="d-inline-block"
              placeholder="Pesquisar..."
              id="filterInput"
              @input="searchProducts"
              v-model="filter"
            />
          </b-col>
        </div>
      </div>

      <b-table
        :items="dataItems"
        striped
        hover
        responsive
        :fields="fields"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
        class="position-relative"
      >
        <template #cell(title)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                rounded="sm"
                v-if="data.item.image"
                size="42"
                :src="data.item.image"
              />
              <b-avatar v-else rounded="sm" variant="light-secondary">
                <feather-icon icon="ImageIcon" />
              </b-avatar>
            </template>
            <span class="d-block text-nowrap">
              <b-icon
                style="width: 9px"
                icon="circle-fill"
                :class="data.item.status ? 'text-success' : 'text-danger'"
                :id="`product-row-${data.item.id}-status-icon`"
              />
              <a
                :href="`https://${school.domain}/cursos/passaportes/${data.item.slug}`"
                target="_blank"
              >
                {{ data.item.title }}
              </a>
            </span>
            <small
              ><strong>{{ data.item.label_image }}</strong></small
            >
          </b-media>
        </template>

        <template #cell(workload)="{ item }">
          {{ item.workload }}
        </template>

        <template #cell(created_at)="{ item }">
          <span v-if="item.updated_at">
            {{ item.updated_at | datePT(true) }}
          </span>
          <span v-else>
            {{ item.created_at | datePT(true) }}
          </span>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex-between">
            <feather-icon
              icon="Trash2Icon"
              size="16"
              v-if="$can('Passaporte - Excluir', 'Produtos')"
              class="cursor-pointer cursor text-danger"
              @click="confirmDelete(data.item.uuid)"
            />

            <feather-icon
              icon="EditIcon"
              class="cursor-pointer cursor"
              size="16"
              v-if="$can('Passaporte - Editar', 'Produtos')"
              @click="
                $router.push({
                  name: 'products-passport-edit',
                  params: { uuid: data.item.uuid },
                })
              "
            />
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rowsTable="rowsTable"
        :currentPage="currentPage"
        :perPage="perPage"
        :totalRows="totalRows"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BIcon,
  BRow,
  BCol,
  BFormInput,
  BBadge,
  BTable,
  BButton,
  BPagination,
  BAvatar,
  BMedia,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import debounce from "lodash/debounce";

export default {
  components: {
    BCard,
    CustomPaginateTable,
    BBadge,
    BIcon,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    BAvatar,
    BMedia,
    vSelect,
  },
  data() {
    return {
      user: {},
      submited: false,
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 200,
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "300",
          value: 300,
        },
      ],
      fields: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "external_id",
          label: "Vindi",
        },
        {
          key: "title",
          label: "Título",
        },
        {
          key: "workload",
          label: "Carga horária",
          class: "text-center",
          thStyle: "width: 350px",
        },
        {
          key: "created_at",
          label: "Data",
          class: "text-center",
          thStyle: "width: 230px",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: "width: 100px",
        },
      ],
      dataItems: [],
    };
  },
  created() {
    this.getData();
    const user = JSON.parse(localStorage.getItem("userData"));
    this.school = user.school;
    this.debouncedSearch = debounce(() => {
      this.getData();
    }, 300);
  },
  methods: {
    async getData() {
      this.$store
        .dispatch("Product/all", {
          uuid: process.env.VUE_APP_PRODUCT_CATEGORY_PASSPORT_UUID,
          currentPage: this.currentPage,
          perPage: this.perPage,
          title: this.filter,
        })
        .then((data) => {
          if (data) {
            this.dataItems = data.data;
            this.rowsTable = data.data.length;
            this.totalRows = data.meta.total;
            this.currentPage = data.meta.current_page;
          }
        });
    },
    searchProducts(term) {
      if (term.length === 0) {
        this.filter = null;
        this.getData();
      } else if (term.length > 2) {
        this.debouncedSearch();
      }
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
    onFiltered(filteredItems) {
      this.metaTotal = filteredItems.length;
      this.currentPage = 1;
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Product/delete", uuid).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
  computed: {
    ...mapState("Product", ["products"]),
  },
  mounted() {
    this.$root.$on("paginate:update", (res) => {
      this.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.perPage = res._perPage;
      this.getData();
    });
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

@import "@core/scss/vue/libs/vue-select.scss";
</style>
